.login-with-google:hover {
  background-color: #fff!important;
  color:#007bff;
  box-shadow: 0px 15px 20px rgba(50, 165, 255, 0.4);
  cursor: pointer;
  
}



#appleid-signin:hover{
  box-shadow: 0px 15px 20px rgba(164, 166, 167, 0.4);
  cursor: pointer;
}