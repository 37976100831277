:root {
  --light-blue: #00a4df;
  --dark-blue: #223f87;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.fdLogo {
  display: block;
  margin: 0 auto;
  height: 150px;
  padding-right: 50px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.auth-wrapper {
  background: #44eaf8;
  /* background-image: linear-gradient(120deg, var(--light-blue) 0%, var(--dark-blue) 100%);
  background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%); */
  background-image: linear-gradient(to top, #dfe9f3 0%, white 100%);
}

.form-icons {
  background-color: transparent !important;
  border-right-color: transparent !important;
}

.signin {
  color: var(--light-blue);
  font-weight: 800;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.passtoggle {
  background-color: transparent !important;
}

.login-form {
  width: 450px;
}

.login-form label {
  color: var(--light-blue);
  font-weight: 600;
  letter-spacing: 1px;
}

.login-form ::placeholder {
  color: #d5d5d5;
}

.login-button {
  background-image: linear-gradient(to right, #00a4df 0%, #223f87 100%);
  height: 50px;
  font-weight: 500;
  margin-bottom: 30px;
  border: none !important;
}

.login-button:hover {
  background-image: linear-gradient(to right, #223f87 0%, #223f87 100%);
}

.login-form .nav-link {
  font-weight: 500;
}

.logout {
  background-color: #00a4df;
  padding: 5px 20px;
  color: #fff;
  font-weight: 500;
  border-radius: 5px;
}

.logout:hover {
  background-color: #0093c8;
  padding: 5px 20px;
  color: #fff;
  font-weight: 500;
  border-radius: 5px;
}

.links-below-button .nav-link {
  padding-left: 0px;
  padding-right: 0px;
  color: #0093c8;
}

.links-below-button .nav-link:hover {
  color: #223f87;
}

.main-container-dashboard {
  height: auto;
  width: 1140px;
}

.logo-text {
  margin-left: 30px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
}

.main-title {
  text-align: center;
  font-weight: 600;
  color: #223f87;
}

.username {
  padding-right: 20px;
  padding-top: 6px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

.current-subs {
  background-color: #e5faff;
  padding: 50px;
  border-radius: 10px;
}

.current-subs p {
  text-align: center;
  font-weight: 500;
}

.subscription-form .form-row.align-items-center {
  justify-content: center;
}

.footer {
  box-shadow: none !important;
}

.old-subs {
  margin-top: 20px;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 20px 50px;
  border-radius: 10px;
}

.buy {
  background-image: linear-gradient(
    to right,
    #00a4df 0%,
    #223f87 100%
  ) !important;
}

/*pricing*/

.pricing-table {
  width: 100%;
  margin: 20px auto;
  text-align: center;
  padding: 10px;
  padding-right: 0;
}

.pricing-table.group {
  display: flex;
}

.blockk {
  width: 30%;
  margin: 0 15px;
  overflow: hidden;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  /*    border: 1px solid red;*/
}
/*Shared properties*/
.title,
.pt-footer {
  color: #fefefe;
  text-transform: capitalize;
  position: relative;
}
.content {
  position: relative;
  color: #fefefe;
  padding: 20px 0 10px 0;
}
/*arrow creation*/
.content:after,
.content:before,
.pt-footer:before,
.pt-footer:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.pt-footer:after,
.pt-footer:before {
  top: 0;
}
.content:after,
.pt-footer:after {
  border-color: rgba(136, 183, 213, 0);
  border-width: 5px;
  margin-left: -5px;
}
/*/arrow creation*/
.price {
  position: relative;
  display: inline-block;
  margin-bottom: 0.625rem;
}
.price span {
  font-size: 3rem;
  letter-spacing: 8px;
  font-weight: bold;
}
.price sup {
  font-size: 1.5rem;
  position: absolute;
  top: 12px;
  left: -12px;
}
.hint {
  font-style: italic;
  font-size: 0.9rem;
}
.features {
  list-style-type: none;
  background: #ffffff;
  text-align: left;
  color: #9c9c9c;
  padding: 30px 22%;
  font-size: 0.9rem;
}
.features li {
  padding: 15px 0;
  width: 100%;
}
.features li span {
  padding-right: 0.4rem;
}
.pt-footer {
  font-size: 0.95rem;
  text-transform: capitalize;
}

.blockk.personal.fl .title {
  font-weight: 700;
  line-height: 26px;
  /* padding-top: 10px; */
  padding: 10px 20px;
  font-size: 22px;
}

/*PERSONAL*/
.personal .title {
  background: #223f87;
}
.personal .content,
.personal .pt-footer {
  background: #00a7e1;
}

.personal .pt-footer {
  background: #eb6379;
  padding: 8px;
  margin-bottom: 0px !important;
}

.personal .pt-footer:hover {
  background: red;
  cursor: pointer;
}
.personal .content:after {
  border-top-color: #82daca;
}
.personal .pt-footer:after {
  border-top-color: #eb6379;
}
/*PROFESSIONAL*/
.professional .title {
  background: #3ec6e0;
}
.professional .content,
.professional .pt-footer {
  background: #53cfe9;
}
.professional .content:after {
  border-top-color: #53cfe9;
}
.professional .pt-footer:after {
  border-top-color: #ffffff;
}
/*BUSINESS*/
.business .title {
  background: #e3536c;
}
.business .content,
.business .pt-footer {
  background: #eb6379;
}
.business .content:after {
  border-top-color: #eb6379;
}
.business .pt-footer:after {
  border-top-color: #ffffff;
}

.forgot-password.text-right.nav-link {
  font-size: 16px;
  font-weight: 500;
}

.forgot-password.text-right.nav-link a {
  color: var(--light-blue);
  font-size: 16px;
  font-weight: 500;
}

.forgot-password.text-right.nav-link a:hover {
  text-decoration: none;
  color: var(--dark-blue);
}

.auth-wrapper:before {
  display: block;
  content: " ";
  position: absolute;
  width: 831px;
  height: 831px;
  bottom: 20px;
  right: 20px;
  z-index: 0;
  background: url(./images/fuel-donkey-white.png) no-repeat;
  opacity: 0.4;
}

.auth-inner {
  z-index: 10;
}

.sign-up-form .input-group-text.form-icons {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.swal-button {
  background-color: var(--dark-blue);
}

.swal2-styled.swal2-confirm,
.swal2-styled.swal2-confirm:focus {
  background-color: var(--dark-blue) !important;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: var(--dark-blue) !important;
}

.auth-wrapper {
  height: 120vh !important;
}

.sign-up-form {
  margin-top: -30px;
}

.sign-up-form .fdLogo {
  height: 100px !important;
}

.links-below-button {
  margin-top: 25px;
}

.sign-up-social-logins {
  margin-top: 0px;
}

.login-with-google:hover {
  background-color: #fff !important;
  color: #007bff;
  box-shadow: 0px 15px 20px rgba(50, 165, 255, 0.4);
  cursor: pointer;
}

#appleid-signin:hover {
  box-shadow: 0px 15px 20px rgba(164, 166, 167, 0.4);
  cursor: pointer;
}

.login-with-google svg {
  margin-right: 5px;
}

.already-registered {
  margin-top: 10px;
}

.swal-modal {
  width: 830px !important;
}
