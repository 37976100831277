.main-container-dashboard {
  width: 1140px;
}

.hint {
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0;
}

.content {
  min-width: 320px;
  min-height: 343px;
}

.auth-inner {
  margin-top: 100px;
}

.swal-htm-text {
  text-align: left;
  list-style-type: square;
}

.swal-htm-text ul {
  list-style-type: square;
}
